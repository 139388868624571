// app/javascript/controllers/datatable_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  
  connect() {
    // Store initial scroll position
    // this.scrollPosition = window.scrollY
  }

  save(event) {
    // Store scroll position before form submission
    // this.scrollPosition = window.scrollY
    
    // const form = event.target.form
    // form.requestSubmit()
  }

  // Optional: Restore scroll position after update
  scrollBack() {
    // if (this.scrollPosition) {
    //   window.scrollTo(0, this.scrollPosition)
    // }
  }

  // Handle sort click
  sort(event) {
    const link = event.currentTarget
    const header = link.closest('th')
    
    // Add loading state
    header.classList.add('animate-pulse')
    
    // Remove loading state after Turbo stream
    this.element.addEventListener('turbo:stream-end', () => {
      header.classList.remove('animate-pulse')
    }, { once: true })
  }
}