// app/javascript/controllers/tabs_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = [ "activeTab", "inactiveTab" ]
  static targets = ['tab', 'panel', 'select', 'hideButton', 'indicator']
  static values = {
    index: Number,
    updateAnchor: Boolean
  }

  connect() {
    this.showPanel()
    // Add keyboard navigation
    this.element.addEventListener('keydown', this.handleKeydown.bind(this))
  }

  togglePanel(event) {
    const clickedIndex = parseInt(event.currentTarget.dataset.index)
    // If clicking the active tab, hide it
    if (this.indexValue === clickedIndex) {
      this.hidePanel()
    } else {
      this.indexValue = clickedIndex
      this.showPanel()
    }
  }

  hidePanel() {
    const currentPanel = this.panelTargets[this.indexValue]
    if (currentPanel) {
      this.prepareTransition(currentPanel, false)
      currentPanel.addEventListener('transitionend', () => {
        currentPanel.classList.add('hidden')
      }, { once: true })
    }
    this.indexValue = -1
    this.updateUI()
    if (this.hasSelectTarget) {
      this.selectTarget.value = -1
    }
    if (this.hasHideButtonTarget) {
      this.hideButtonTarget.hidden = true
    }
  }

  showPanel() {
    // Hide any visible panels first
    this.panelTargets.forEach((panel, index) => {
      if (index !== this.indexValue && !panel.classList.contains('hidden')) {
        this.prepareTransition(panel, false)
        panel.classList.add('hidden')
      }
    })

    // Show the selected panel
    if (this.indexValue >= 0) {
      const panel = this.panelTargets[this.indexValue]
      panel.classList.remove('hidden')
      // Trigger reflow
      panel.offsetHeight
      this.prepareTransition(panel, true)
      if (this.hasHideButtonTarget) {
        this.hideButtonTarget.hidden = false
      }
    }

    this.updateUI()
  }

  prepareTransition(panel, show) {
    const enterClasses = panel.dataset.transitionEnter?.split(' ') || []
    const leaveClasses = panel.dataset.transitionLeave?.split(' ') || []
    
    if (show) {
      panel.classList.remove(...leaveClasses)
      panel.classList.add(...enterClasses)
    } else {
      panel.classList.remove(...enterClasses)
      panel.classList.add(...leaveClasses)
    }
  }

  updateUI() {
    // Update tabs
    this.tabTargets.forEach((tab, index) => {
      const indicator = tab.querySelector('[data-radio-tabs-target="indicator"]')
      if (indicator) {
        indicator.style.opacity = index === this.indexValue ? "1" : "0"
      }
    })

    // Update select if present
    if (this.hasSelectTarget) {
      this.selectTarget.value = this.indexValue
    }
  }

  handleKeydown(event) {
    const tabs = this.tabTargets
    const currentIndex = this.indexValue
    
    switch(event.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        event.preventDefault()
        this.indexValue = currentIndex === tabs.length - 1 ? 0 : currentIndex + 1
        this.showPanel()
        tabs[this.indexValue].focus()
        break
      case 'ArrowLeft':
      case 'ArrowUp':
        event.preventDefault()
        this.indexValue = currentIndex === 0 ? tabs.length - 1 : currentIndex - 1
        this.showPanel()
        tabs[this.indexValue].focus()
        break
      case 'Home':
        event.preventDefault()
        this.indexValue = 0
        this.showPanel()
        tabs[0].focus()
        break
      case 'End':
        event.preventDefault()
        this.indexValue = tabs.length - 1
        this.showPanel()
        tabs[tabs.length - 1].focus()
        break
      case 'Escape':
        event.preventDefault()
        this.hidePanel()
        break
    }
  }
}