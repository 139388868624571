import { Controller } from "@hotwired/stimulus"
import { FetchRequest, get, post, put, patch, destroy } from '@rails/request.js'
import { enter, leave } from 'el-transition'

const RequestStatusConstants = Object.freeze({
  ACTIVE:     'active',
  ERROR:      'error',
  INACTIVE:   'inactive',
  PROCESSING: 'processing',
  SUCCESS:    'success',
})

const RequestMethods = Object.freeze({
  GET:    'get',
  POST:   'post',
  PUT:    'put',
  PATCH:  'patch',
  DELETE: 'delete'
})

const Defaults = Object.freeze({
  ENTITY_CLASS:   '.entity',
  TURBO_STREAM:   'turbo-stream',
  TARGETS:        ['button', 'status', 'form', 'loading', 'success', 'error'],
  METHOD:         RequestMethods.GET,
  BULK_PARAM:     'ids[]'
})

export default class extends Controller {
  static classes = Object.values(RequestStatusConstants)
  static targets = Defaults.TARGETS
  static values = { 
    url: String,
    method: { type: String, default: Defaults.METHOD },
    bulk: { type: Boolean, default: false },
    confirm: { type: String, default: '' }
  }

  initialize() {
    this.handleResponse = this.handleResponse.bind(this)
    this.handleError = this.handleError.bind(this)
    this.bulkActionComplete = this.bulkActionComplete.bind(this)
  }

  connect() {
    // Initialize state indicators as hidden
    if (this.hasLoadingTarget) this.loadingTarget.hidden = true
    if (this.hasSuccessTarget) this.successTarget.hidden = true
    if (this.hasErrorTarget) this.errorTarget.hidden = true
  }

  // Main entry point for requests
  async submit(event) {
    event?.preventDefault()
    
    if (this.confirmValue && !confirm(this.confirmValue)) {
      return
    }

    const data = this.buildRequestData()
    return this.performRequest(this.urlValue, data)
  }

  // Specialized actions
  async splitLineItem(event) {
    event?.preventDefault()
    const itemId = event.currentTarget.dataset.itemId
    const quantity = event.currentTarget.dataset.quantity
    
    const data = new FormData()
    data.append('quantity', quantity)
    
    return this.performRequest(`/line_items/${itemId}/split`, data)
  }

  async updateShipmentStatus(event) {
    event?.preventDefault()
    const status = event.currentTarget.dataset.status
    const shipmentId = event.currentTarget.dataset.shipmentId
    
    const data = new FormData()
    data.append('status', status)
    
    return this.performRequest(`/shipments/${shipmentId}`, data)
  }

  // Bulk actions support
  async bulkAction(event) {
    event?.preventDefault()
    
    if (!this.bulkValue) return

    const selectedIds = this.getSelectedIds()
    if (selectedIds.length === 0) {
      alert('Please select items to process')
      return
    }

    const data = new FormData()
    selectedIds.forEach(id => data.append(Defaults.BULK_PARAM, id))
    
    return this.performRequest(this.urlValue, data)
  }

  getSelectedIds() {
    return Array.from(document.querySelectorAll('input[type="checkbox"][name="selected[]"]:checked'))
      .map(checkbox => checkbox.value)
  }

  // Request handling
  async performRequest(url, data = null) {
    try {
      await this.setProcessingState()
      const response = await this.createRequest(url, data)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleError(error)
    }
  }

  createRequest(url, data) {
    const method = this.methodValue.toLowerCase()
    const options = this.buildRequestOptions(data, method)
    
    switch (method) {
      case RequestMethods.GET:
        // For GET requests, append data to URL if present
        if (data instanceof FormData) {
          const params = new URLSearchParams(data)
          url = `${url}${url.includes('?') ? '&' : '?'}${params.toString()}`
        }
        return get(url, options)
      case RequestMethods.POST:
        return post(url, options)
      case RequestMethods.PUT:
        return put(url, options)
      case RequestMethods.PATCH:
        return patch(url, options)
      case RequestMethods.DELETE:
        return destroy(url, options)
      default:
        throw new Error(`Unsupported request method: ${this.methodValue}`)
    }
  }

  // State transitions with el-transition
  async setProcessingState() {
    this.element.classList.remove(...this.constructor.classes)
    this.element.classList.add(RequestStatusConstants.PROCESSING)
    this.toggleElementDisabledState(this.buttonTarget, true)

    if (this.hasLoadingTarget) {
      await this.hideAllStateIndicators()
      await enter(this.loadingTarget)
    }
  }

  async setSuccessState() {
    this.element.classList.remove(...this.constructor.classes)
    this.element.classList.add(RequestStatusConstants.SUCCESS)
    this.toggleElementDisabledState(this.buttonTarget, false)

    if (this.hasSuccessTarget) {
      await this.hideAllStateIndicators()
      await enter(this.successTarget)
      
      // Optionally hide success message after delay
      setTimeout(async () => {
        await leave(this.successTarget)
      }, 3000)
    }
  }

  async setErrorState() {
    this.element.classList.remove(...this.constructor.classes)
    this.element.classList.add(RequestStatusConstants.ERROR)
    this.toggleElementDisabledState(this.buttonTarget, false)

    if (this.hasErrorTarget) {
      await this.hideAllStateIndicators()
      await enter(this.errorTarget)
    }
  }

  async hideAllStateIndicators() {
    const promises = []
    
    if (this.hasLoadingTarget && !this.loadingTarget.hidden) {
      promises.push(leave(this.loadingTarget))
    }
    if (this.hasSuccessTarget && !this.successTarget.hidden) {
      promises.push(leave(this.successTarget))
    }
    if (this.hasErrorTarget && !this.errorTarget.hidden) {
      promises.push(leave(this.errorTarget))
    }

    await Promise.all(promises)
  }

  // Response handling
  async handleResponse(response) {
    if (response.ok) {
      await this.setSuccessState()
      this.dispatch('success', { detail: { response } })
      
      if (this.bulkValue) {
        this.bulkActionComplete(response)
      }
    } else {
      await this.setErrorState()
      this.dispatch('error', { detail: { response } })
    }
    return response
  }

  async handleError(error) {
    await this.setErrorState()
    this.dispatch('error', { detail: { error } })
    throw error
  }

  bulkActionComplete(response) {
    // Clear checkboxes after bulk action
    document.querySelectorAll('input[type="checkbox"][name="selected[]"]:checked')
      .forEach(checkbox => checkbox.checked = false)
    
    this.dispatch('bulkActionComplete', { detail: { response } })
  }

  // Utility methods
  buildRequestData() {
    if (this.hasFormTarget) {
      return new FormData(this.formTarget)
    }
    return new FormData()
  }

  buildRequestOptions(data, method) {
    const options = {
      responseKind: Defaults.TURBO_STREAM
    }

    // Only include body for non-GET requests
    if (method !== RequestMethods.GET && data) {
      options.body = data
    }

    return options
  }

  toggleElementDisabledState(el, state) {
    el.disabled = state
  }
}