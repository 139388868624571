import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "display", "displayButton", "editButton"]

  showForm(event) {
    event.preventDefault()
    this.fieldTargets.forEach(field => field.classList.remove('hidden'))
    this.displayTargets.forEach(display => display.classList.add('hidden'))
    this.displayButtonTarget.classList.add('hidden')
    this.editButtonTarget.classList.remove('hidden')
  }

  async hideForm(event) {
    event.preventDefault()
    
    // Show loading state
    const button = event.currentTarget
    const originalContent = button.innerHTML
    button.innerHTML = '<i class="fa-solid fa-spinner-third fa-spin"></i>'
    button.disabled = true
    
    try {
      const itemId = this.element.id
      const response = await fetch(window.location.pathname, {
        headers: {
          'Accept': 'text/html',
          'Turbo-Frame': 'false'
        }
      })
      
      if (response.ok) {
        const html = await response.text()
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')
        const newElement = doc.getElementById(itemId)
        
        if (newElement) {
          newElement.querySelector(`[data-row-edit-target="field"]`)?.classList.add('hidden')
          newElement.querySelector(`[data-row-edit-target="display"]`)?.classList.remove('hidden')
          newElement.querySelector(`[data-row-edit-target="displayButton"]`)?.classList.remove('hidden')
          newElement.querySelector(`[data-row-edit-target="editButton"]`)?.classList.add('hidden')
          
          // Add a subtle transition effect
          this.element.style.opacity = '0.5'
          await new Promise(resolve => setTimeout(resolve, 150)) // Short delay for visual feedback
          
          this.element.replaceWith(newElement)
        }
      }
    } catch (error) {
      console.error('Error:', error)
      // Reset button state on error
      button.innerHTML = originalContent
      button.disabled = false
    }
  }
}