// app/javascript/controllers/select_tailwind_controller.js
import SelectController from "./select_controller"

export default class extends SelectController {
  customOptions() {
    const parentOptions = super.customOptions()
    
    return {
      ...parentOptions,
      // Custom class overrides
      wrapperClass: 'custom-select p-0',
      controlClass: 'custom-select-control',
      dropdownClass: 'custom-select-dropdown',
      dropdownContentClass: 'custom-select-dropdown-content',
      itemClass: 'custom-select-item',
      optionClass: 'custom-select-option',
      optgroupClass: 'custom-select-optgroup',
      noResultsClass: 'custom-select-no-results',
      inputClass: 'custom-select-input',
      loadingClass: 'custom-select-loading',
      clearButtonClass: 'custom-select-clear',
      
      render: {
        option: this.renderOption.bind(this),
        item: this.renderItem.bind(this),
        no_results: this.renderNoResults.bind(this)
      }
    }
  }

  renderOption(data, escape) {
    return `<div class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900" role="option">
      <span class="block truncate font-normal">${escape(data.text)}</span>
      <span class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 hidden">
        <svg class="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clip-rule="evenodd" />
        </svg>
      </span>
    </div>`
  }

  renderItem(data, escape) {
    return `<div class="grid grid-cols-1">
      <span class="w-full col-start-1 row-start-1 truncate pr-6">${escape(data.text)}</span>
      <svg class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.22 10.22a.75.75 0 0 1 1.06 0L8 11.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-2.25 2.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 0 1 0-1.06ZM10.78 5.78a.75.75 0 0 1-1.06 0L8 4.06 6.28 5.78a.75.75 0 0 1-1.06-1.06l2.25-2.25a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
      </svg>
    </div>`
  }

  renderNoResults(data, escape) {
    return '<div class="custom-select-no-results py-2 px-3 text-sm text-gray-600">No results found</div>'
  }
}