// app/javascript/controllers/select_country_controller.js
import SelectTailwindController from "./select_tailwind_controller"

export default class extends SelectTailwindController {
  customOptions() {
    const parentOptions = super.customOptions()
    
    return {
      ...parentOptions,
      valueField: 'alpha2',
      labelField: 'alpha3',
      searchField: ['alpha3', 'name'],
      maxItems: 1,
      maxOptions: 5
    }
  }

  renderOption(item, escape) {
    return `<div class="flex items-center gap-2 py-2 pl-3 pr-9 cursor-default">
      <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.alpha2}.svg" 
           class="w-4 h-3 object-cover" 
           alt="Flag of ${escape(item.name)}" />
      <p class="text-sm leading-6">${escape(item.alpha3)}</p>
    </div>`
  }

  renderItem(item, escape) {
    return `<div class="grid grid-cols-1">
      <div class="w-full col-start-1 row-start-1 flex items-center gap-2 truncate pr-6">
        <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.alpha2}.svg" 
             class="w-4 h-3 object-cover" 
             alt="Flag of ${escape(item.name)}" />
        <p class="text-sm leading-6">${escape(item.alpha3)}</p>
      </div>
      <svg class="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.22 10.22a.75.75 0 0 1 1.06 0L8 11.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-2.25 2.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 0 1 0-1.06ZM10.78 5.78a.75.75 0 0 1-1.06 0L8 4.06 6.28 5.78a.75.75 0 0 1-1.06-1.06l2.25-2.25a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
      </svg>
    </div>`
  }
}