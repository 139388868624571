// app/javascript/controllers/search_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  loading() {
    this.element.classList.add('opacity-50')
    // Disable inputs during submission
    this.element.querySelectorAll('input, select').forEach(el => el.disabled = true)
  }

  finished() {
    this.element.classList.remove('opacity-50')
    // Re-enable inputs
    this.element.querySelectorAll('input, select').forEach(el => el.disabled = false)
  }
}