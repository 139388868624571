// app/javascript/controllers/select_controller.js
import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {
    this.select = new TomSelect(this.element, {
      mode: this.element.multiple ? "multi" : "single",
      maxItems: this.element.multiple ? null : 1,
      persist: true,
      openOnFocus: true,
      hideSelected: true,
      
      onInitialize: function() {
        if (this.input.dataset.hadFocus === 'true') {
          this.focus()
        }
      },
      
      onItemAdd: function(){
        this.setTextboxValue('')
        this.refreshOptions()
      },
      
      ...this.customOptions()
    })
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  // To be overridden by child controllers
  customOptions() {
    return {}
  }
}