import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["nextPageLink"]
  static values = { 
    rootMargin: { type: String, default: "40px" }
  }

  initialize() {
    this.observeNextPageLink()
  }

  async loadNextPage(event) {
    event?.preventDefault()
    
    const html = await this.loadNextPageHTML()
    this.nextPageLink.outerHTML = html
    
    // Wait a bit before observing the new link to prevent rapid loading
    setTimeout(() => this.observeNextPageLink(), 500)
  }

  // Private

  async observeNextPageLink() {
    if (!this.hasNextPageLinkTarget) return

    await nextFrame()
    await nextIntersection(this.nextPageLinkTarget, this.intersectionOptions)
    this.loadNextPage()
  }

  async loadNextPageHTML() {
    const response = await fetch(this.nextPageLinkTarget.href, {
      headers: { Accept: "text/vnd.turbo-stream.html" }
    })
    return await response.text()
  }

  get intersectionOptions() {
    return {
      rootMargin: this.rootMarginValue
    }
  }
}

function nextFrame() {
  return new Promise(requestAnimationFrame)
}

function nextIntersection(element, options = {}) {
  return new Promise(resolve => {
    new IntersectionObserver(([ entry ], observer) => {
      if (!entry.isIntersecting) return
      observer.disconnect()
      resolve()
    }, options).observe(element)
  })
}