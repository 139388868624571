// app/javascript/controllers/status_display_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String
  }

  connect() {
    this.handleStatus = this.handleStatus.bind(this)
    document.addEventListener('autosave:status', this.handleStatus)
    this.element.classList.add('transition-opacity', 'duration-300', 'ease-in-out')
  }

  disconnect() {
    document.removeEventListener('autosave:status', this.handleStatus)
  }

  handleStatus(event) {
    if (event.detail.statusId !== this.idValue) return

    const statusMap = {
      saving: {
        html: "<i class='fa-solid fa-ellipsis opacity-70' title='Saving...'></i>",
        classes: ['text-gray-500']
      },
      saved: {
        html: "<i class='fa-solid fa-check' title='Saved'></i>",
        classes: ['text-green-500']
      },
      saveFailed: {
        html: "<i class='fa-solid fa-circle-exclamation' title='Error'></i>",
        classes: ['text-red-500']
      }
    }

    const status = statusMap[event.detail.status]
    if (!status) return

    // Remove any existing status classes
    this.element.classList.remove('text-gray-500', 'text-green-500', 'text-red-500', 'opacity-0')
    
    // Add new status classes
    status.classes.forEach(className => this.element.classList.add(className))
    
    // Update content
    this.element.innerHTML = status.html

    // Clear status after delay (except for 'saving' state)
    if (event.detail.status !== 'saving') {
      setTimeout(() => {
        this.element.classList.add('opacity-0')
        setTimeout(() => {
          this.element.innerHTML = ''
        }, 300) // Match transition duration
      }, 2000)
    }
  }
}