// app/javascript/controllers/inline_edit_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["display", "form"]
  
  edit() {
    this.displayTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
    this.formTarget.querySelector("input").focus()
  }
  
  cancel() {
    this.displayTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
  }
  
  save(event) {
    event.preventDefault()
    const form = event.target
    
    fetch(form.action, {
      method: form.method,
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: new FormData(form)
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      this.cancel()
    })
  }
}