// app/javascript/controllers/drag_drop_controller.js
import { Controller } from "@hotwired/stimulus"
import { dragAndDrop, updateConfig } from '@formkit/drag-and-drop'

export default class extends Controller {
  static values = {
    disabled: Boolean
  }

  connect() {
    this.initializeDragAndDrop()
  }

  // Watch for changes to disabled value
  disabledValueChanged() {
    updateConfig(this.element, {
      disabled: this.disabledValue,
      dropZone: !this.disabledValue // Toggle dropZone with disabled state
    })
  }

  initializeDragAndDrop() {
    dragAndDrop({
      parent: this.element,
      getValues: () => {
        return Array.from(this.element.querySelectorAll('[data-draggable="true"]'))
          .map(node => ({ id: node.dataset.lineItemId }))
      },
      setValues: async (values = []) => {
        if (this.hasMoved(values)) {
          const movedItem = this.findMovedItem(values)
          
          if (!movedItem) {
            console.warn('No moved item found')
            return
          }
          
          const newPackageId = this.element.dataset.packageId
          
          try {
            const response = await this.moveItem(movedItem.id, newPackageId)
            const html = await response.text()
            Turbo.renderStreamMessage(html)
          } catch (error) {
            console.error('Move failed:', error)
          }
        }
      },
      config: {
        draggable: (el) => {
          return el.hasAttribute('data-draggable') && el.dataset.draggable === 'true'
        },
        disabled: this.disabledValue,
        dropZone: !this.disabledValue, // Initial dropZone state
        group: 'packages',
        dropZoneParentClass: 'bg-gray-50/50',
        dropZoneClass: '!border-2 !border-dashed !border-blue-500 !rounded-lg p-2',
        draggingClass: 'opacity-50 cursor-grabbing',
        dragOverClass: '!bg-blue-50 !border-blue-500',
        dragPlaceholderClass: 'cursor-grab',
        dragEffectAllowed: 'move',
        dragDropEffect: 'move',
        threshold: {
          horizontal: 0.2,
          vertical: 0.2
        },
        root: document,
        nativeDrag: true
      }
    })
  }

  hasMoved(values) {
    const currentIds = Array.from(this.element.querySelectorAll('[data-draggable="true"]'))
      .map(node => node.dataset.lineItemId)
    const newIds = values.map(v => v?.id).filter(Boolean)
    
    console.log('Checking move:', { currentIds, newIds })
    return JSON.stringify(currentIds) !== JSON.stringify(newIds)
  }

  findMovedItem(values) {
    const currentIds = Array.from(this.element.querySelectorAll('[data-draggable="true"]'))
      .map(node => node.dataset.lineItemId)
    
    console.log('Finding moved item:', { values, currentIds })
    
    // Find a value that exists in the new values but not in current
    const addedItem = values.find(v => !currentIds.includes(v?.id))
    if (addedItem) return addedItem
    
    // Or find a value that's in a different position
    return values.find((v, i) => v?.id !== currentIds[i])
  }

  async moveItem(lineItemId, packageId) {
    console.log('Moving item:', { lineItemId, packageId })
    return await fetch(`/packages/${packageId}/line_items/${lineItemId}/move`, {
      method: 'PATCH',
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      }
    })
  }
}