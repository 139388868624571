// app/javascript/controllers/debounce_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: { type: Number, default: 500 }
  }

  initialize() {
    this.debouncedSubmit = this.debounce(this.submit.bind(this), this.delayValue)
  }

  input(event) {
    this.debouncedSubmit(event)
  }

  submit(event) {
    this.element.form.requestSubmit()
  }

  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
}