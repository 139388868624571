// app/javascript/controllers/modal_controller.js
import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  connect() {
    super.connect()
    document.addEventListener("turbo:submit-end", this.handleSubmitEnd.bind(this))
  }

  disconnect() {
    super.disconnect()
    document.removeEventListener("turbo:submit-end", this.handleSubmitEnd.bind(this))
  }

  open() {
    // Reset any existing form
    const form = this.dialogTarget.querySelector('form')
    if (form) form.reset()
    
    super.open()
  }

  handleSubmitEnd(event) {
    // Check if the response is a success (no validation errors)
    if (event.detail.success && !event.detail.formSubmission.result.error) {
      this.close()
    }
  }
}