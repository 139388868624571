import { Controller } from "@hotwired/stimulus"

/**
 * Package Template Controller
 * 
 * Manages the package template form interaction by:
 * - Loading template dimensions when a template is selected
 * - Disabling dimension fields when populated from template
 * - Providing reset functionality to clear form
 */
export default class extends Controller {
  static targets = ["height", "length", "resetButton", "template", "width"]
  
  static values = {
    fetchEndpoint: String
  }
  static dimensionFields = ["length", "width", "height"]

  connect() {
    // Add a small delay before initial update to ensure DOM is ready
    setTimeout(() => this.updatePackageTemplate(), 100)
  }

  updatePackageTemplate() {
    const templateId = this.templateTarget.value
    
    if (!templateId) {
      return this.resetForm()
    }
    
    // Debounce multiple rapid calls
    if (this.fetchTimeout) {
      clearTimeout(this.fetchTimeout)
    }
    
    this.fetchTimeout = setTimeout(() => {
      this.fetchTemplateData(templateId)
    }, 50)
  }

  async fetchTemplateData(templateId) {
    const url = `/package_templates/${templateId}.json`
    console.log('Fetching template from:', url)
    
    try {
      // Add retry logic
      for (let i = 0; i < 3; i++) {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            },
            credentials: 'same-origin'
          })
          
          if (response.ok || response.status === 304) {
            const template = await response.json()
            console.log('Template data:', template)
            
            if (template) {
              this.updateDimensionFields(template)
              this.resetButtonTarget.hidden = false
              return // Success - exit the retry loop
            }
          }
        } catch (fetchError) {
          console.warn(`Fetch attempt ${i + 1} failed:`, fetchError)
          if (i === 2) throw fetchError // Rethrow on final attempt
          await new Promise(resolve => setTimeout(resolve, 100 * (i + 1))) // Increasing delay between retries
        }
      }
    } catch (error) {
      console.error("Error fetching template:", error)
      console.error("Template ID:", templateId)
      console.error("Full error:", error.stack)
      this.handleFetchError(error)
    }
  }

  updateDimensionFields(template) {
    const autosaveController = this.element.querySelector('[data-controller="autosave"]')
    if (autosaveController) {
      autosaveController.setAttribute('data-autosave-disabled-value', 'true')
    }

    this.constructor.dimensionFields.forEach(dimension => {
      const target = this[`${dimension}Target`]
      const value = template[dimension]
      
      target.value = value
      target.disabled = value ? true : false
      
      // Force a change event after setting the value
      target.dispatchEvent(new Event('input', { bubbles: true }))
      target.dispatchEvent(new Event('change', { bubbles: true }))
    })

    // Re-enable autosave
    if (autosaveController) {
      setTimeout(() => {
        autosaveController.setAttribute('data-autosave-disabled-value', 'false')
        this.templateTarget.dispatchEvent(new Event('change', { bubbles: true }))
      }, 100)
    }
  }

  resetForm() {
    // Notify autosave we're about to make changes
    const autosaveController = this.element.querySelector('[data-controller="autosave"]')
    if (autosaveController) {
      autosaveController.setAttribute('data-autosave-disabled-value', 'true')
    }

    this.resetButtonTarget.hidden = true
    this.templateTarget.value = ''
    
    this.constructor.dimensionFields.forEach(dimension => {
      const target = this[`${dimension}Target`]
      target.value = null
      target.disabled = false
      
      // Force a change event after resetting
      target.dispatchEvent(new Event('input', { bubbles: true }))
      target.dispatchEvent(new Event('change', { bubbles: true }))
    })

    // Re-enable autosave and trigger a save
    if (autosaveController) {
      autosaveController.setAttribute('data-autosave-disabled-value', 'false')
      const event = new Event('change', { bubbles: true })
      this.templateTarget.dispatchEvent(event)
    }
  }
}