import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items", "item"]
  static values = {
    wrapperSelector: String
  }

  connect() {
    this.wrapperSelector = this.wrapperSelectorValue || '.nested-fields'
  }

  add(event) {
    event.preventDefault()
    
    const template = document.getElementById(this.templateId)
    const content = template.content.cloneNode(true)
    
    // Replace the template token with a unique timestamp
    const timestamp = new Date().getTime()
    const html = content.querySelector(this.wrapperSelector)
      .outerHTML.replace(/TEMPLATE_RECORD/g, timestamp)
    
    this.itemsTarget.insertAdjacentHTML('beforeend', html)
  }

  remove(event) {
    event.preventDefault()
    const wrapper = event.target.closest(this.wrapperSelector)
    
    if (wrapper) {
      // Optionally mark for destruction instead of removing
      // const destroyInput = wrapper.querySelector('input[name*="_destroy"]')
      // if (destroyInput) {
      //   destroyInput.value = '1'
      //   wrapper.style.display = 'none'
      // } else {
        wrapper.remove()
      // }
    }
  }

  get templateId() {
    return this.element.dataset.nestedFormTemplateId
  }
}