import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quantity", "resetButton", "weight", "suffix"]
  
  static values = {
    precalculatedWeight: Number,
    suffix: { type: String, default: "" }
  }

  connect() {
    if (this.hasWeightTarget) {
      this.initializeWeightCalculator()
    }
  }

  initializeWeightCalculator() {
    this.updateTotalWeight()
    this.toggleResetButton()
  }

  updateTotalWeight() {
    const { quantity } = this.getCalculationParams()
    this.updateWeightPlaceholder(quantity)
    this.updateSuffix(quantity)
    this.toggleResetButton()
  }

  resetWeight() {
    if (!this.hasWeightTarget) return
    
    const { quantity } = this.getCalculationParams()
    this.resetWeightInput()
    this.updateWeightPlaceholder(quantity)
    this.updateSuffix(quantity)
    this.toggleResetButton()
  }

  getCalculationParams() {
    return {
      defaultWeight: this.precalculatedWeightValue,
      hasDefaultWeight: this.precalculatedWeightValue > 0,
      quantity: this.hasQuantityTarget ? (parseInt(this.quantityTarget.value) || 1) : 1,
      hasCustomWeight: this.hasWeightTarget && 
                      this.weightTarget.value > 0 && 
                      this.weightTarget.value != this.precalculatedWeightValue
    }
  }

  updateWeightPlaceholder(quantity) {
    if (!this.hasWeightTarget) return
    const weightPerUnit = this.precalculatedWeightValue / quantity
    const formattedWeight = this.formatWeight(weightPerUnit)
    this.weightTarget.placeholder = formattedWeight
  }

  formatWeight(weight, quantity = 1) {
    return weight.toFixed(2)
  }

  updateSuffix(quantity = 1) {
    if (!this.hasSuffixTarget) return
    
    let suffix = quantity > 1 ? 'ea.' : ''
    
    if (this.suffixValue) {
      suffix = quantity > 1 ? `${this.suffixValue}/ea.` : this.suffixValue
    }

    // Add exactly one space before the suffix
    this.suffixTarget.textContent = ` ${suffix}`
    this.suffixTarget.hidden = !this.weightTarget.value && !this.weightTarget.placeholder
  }

  resetWeightInput() {
    if (this.hasWeightTarget && this.weightTarget.value != 0) {
      this.weightTarget.value = null
    }
  }

  toggleResetButton() {
    if (!this.hasResetButtonTarget || !this.hasWeightTarget) return
    
    const hasWeightValue = this.weightTarget.value && this.weightTarget.value.length > 0
    this.resetButtonTarget.hidden = !hasWeightValue
  }
}