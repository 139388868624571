// app/javascript/controllers/infinite_scroll_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trigger"]
  
  connect() {
    this.createObserver()
  }

  disconnect() {
    this.observer?.disconnect()
  }

  triggerTargetConnected(element) {
    // Ensure observer exists before using it
    if (!this.observer) {
      this.createObserver()
    }
    this.observer.observe(element)
  }

  createObserver() {
    if (this.observer) return // Don't create multiple observers

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadMore(entry.target)
        }
      })
    })
  }

  async loadMore(trigger) {
    const nextPage = trigger.dataset.nextUrl
    if (!nextPage) return

    // Prevent double loading
    this.observer.unobserve(trigger)

    try {
      const response = await fetch(nextPage, {
        headers: { 
          Accept: "text/vnd.turbo-stream.html",
          "X-Requested-With": "XMLHttpRequest"
        }
      })

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      
      const html = await response.text()
      Turbo.renderStreamMessage(html)
    } catch (error) {
      console.error("Error loading more items:", error)
      // Reobserve the trigger in case of error
      this.observer.observe(trigger)
    }
  }
}